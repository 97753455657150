
import {from as observableFrom,  Observable, ConnectableObservable, Observer, Subscription, fromEvent } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Growths } from './../_interfaces/growths.interface';
import { Baby } from './../_interfaces/baby.interface';
import { BabyheadService } from './../_services/babyhead.service';
import { InternalizationService } from './../_services/internalization.service';
import { filter, map, catchError } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-nutrizione',
  templateUrl: './nutrizione.component.html',
  styleUrls: ['./nutrizione.component.scss']
})
export class NutrizioneComponent implements OnInit {
  
  isLoading = false;
  isDevelop = false;

  baby_id:any;
  mother:any
  babydata:any
  growths:any;

  constructor(private http: HttpClient, 
    private cdr:ChangeDetectorRef,
    private babyheadService:BabyheadService, 
    private translate:InternalizationService) { }

  ngOnInit() {
    if(!localStorage.baby) {
      localStorage.setItem('baby', '822')
    }
    this.baby_id = localStorage.getItem('baby')
    this.isLoading = true
    console.log('baby id: '+this.baby_id)
    const obsvPromiseHead = observableFrom(this.babyheadService.babiHead(this.baby_id))
   .subscribe( (head:any) => {

    this.cdr.detectChanges();
      let today = moment().format('YYYY-MM-DD')
      let dayslife = moment().diff(head.baby.birthday, 'days');
      let dayslifeComplete = moment().diff(head.baby.birthday+' '+head.baby.birthtime, 'days');
      head['daysLife'] = dayslife
      head['daysLifeComplete'] = dayslifeComplete
      this.babydata = head
      localStorage.setItem('nascita', head.baby.birthday)
      localStorage.setItem('peso', head.growthsLast.weight)
      this.isLoading = false
      
    }, error =>{
      console.log(error)
    })

  }


}
