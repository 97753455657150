export class Prescription {
  constructor(
    public position?: number,
    public latte?: LatteModel,
    public alimenti?: AlimentiModel[],
  ) {if(!alimenti)this.alimenti = new Array<AlimentiModel>()}
}

export class LatteModel {
  constructor(
    public supplement?: number,
    public int?: number,
    public nmeals?: number,
    public volumekg?: number,
    public dilution?: number,
    public volumeml?: number
  ) {}
}

export class AlimentiModel {
  constructor(
    public position?: number,
    public supplement?: number,
    public int?: number,
    public nmeals?: number,
    public volumekg?: number,
    public dilution?: number,
    public volumeml?: number
  ) {}
}