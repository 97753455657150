import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import * as moment from 'moment';

import {MatAutocompleteModule} from '@angular/material/autocomplete';

import { AppComponent } from './app.component';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';
import { NutrizioneComponent } from './nutrizione/nutrizione.component';
import { EnteralComponent } from './nutrizione/enteral/enteral.component';
import { BabyComponent } from './baby/baby.component';
import { LayoutModule } from '@angular/cdk/layout';
import { DragDropModule  } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InternalizationService } from './_services/internalization.service';
import { NutrizioneService } from './_services/nutrizione.service';
import { FiltroPerLetteraPipe } from './_pipes/filtro-per-lettera.pipe';
import {MatChipsModule} from '@angular/material/chips';
import { 
  MatToolbarModule, 
  MatButtonModule, 
  MatSidenavModule, 
  MatIconModule, 
  MatListModule, 
  MatMenuModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialogModule,
  MatCardModule,
  MatExpansionModule,
  MatGridListModule,
  MatDividerModule,
  MatTableModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatProgressSpinnerModule,
  MatRadioModule
  } from '@angular/material';
import { DialogComponent } from './nutrizione/enteral/dialog/dialog.component';

import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { ParenteralComponent } from './nutrizione/parenteral/parenteral.component';
import { HeaderParentalComponent } from './nutrizione/parenteral/header-parental/header-parental.component';
import { DragTablesComponent } from './nutrizione/parenteral/drag-tables/drag-tables.component';
import { RecommendedComponent } from './nutrizione/parenteral/recommended/recommended.component';
import { PrescriptionComponent } from './nutrizione/parenteral/prescription/prescription.component';
import { IntakesComponent } from './nutrizione/parenteral/intakes/intakes.component';
import { FooterComponent } from './footer/footer.component';
import { NutrizioneHeadComponent } from './nutrizione/nutrizione-head/nutrizione-head.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

const routesApp:Routes = [
  {path:'nutrizione' , component:NutrizioneComponent, data: { state: 'nutrizione'}},
  {path:'' , component:NutrizioneComponent, pathMatch: 'full', data: { state: 'home'}},
  {path:'**' , redirectTo:'errore' },
]


@NgModule({
  declarations: [
    AppComponent,
    SidebarNavComponent,
    NutrizioneComponent,
    EnteralComponent,
    BabyComponent,
    FiltroPerLetteraPipe,
    DialogComponent,
    ParenteralComponent,
    HeaderParentalComponent,
    DragTablesComponent,
    RecommendedComponent,
    PrescriptionComponent,
    IntakesComponent,
    FooterComponent,
    NutrizioneHeadComponent
  ],
  entryComponents: [
    DialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(routesApp),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    HttpClientModule,
    LayoutModule,
    AngularFontAwesomeModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatRadioModule,
    MatIconModule,
    MatListModule,
    MatMenuModule,
    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatCardModule,
    MatExpansionModule,
    MatGridListModule,
    FlexLayoutModule,
    MatDividerModule,
    MatTableModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    DragDropModule,
    MatChipsModule
  ],
  providers: [
    InternalizationService, 
    NutrizioneService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
