import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Growths } from './../_interfaces/growths.interface'

@Injectable({
  providedIn: 'root'
})
export class BabyService {

  constructor(private http: HttpClient) { }

  baby(baby){
   let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let body = JSON.stringify({'id':baby});
    return this.http.post('http://api.nutrizione.jef.it/service/babies/index?method=get', body, httpOptions)
  }

  growths(baby){
   let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let body = JSON.stringify({'id':baby});
    return this.http.post('http://api.nutrizione.jef.it/service/growths/baby?method=get', body, httpOptions)
  }
  
  newGrowths(babyWeight){
    console.log(babyWeight)
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
    let body = JSON.stringify(babyWeight);
    console.log(body)
    return this.http.post('http://api.nutrizione.jef.it/service/rest/growths-restful', body, httpOptions)
   }
}
