import { Injectable } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class InternalizationService {
  selected:any
  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'it']);
    translate.setDefaultLang('it');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/en|it/) ? browserLang : 'it');
   }
}
