import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-prescription',
  templateUrl: './prescription.component.html',
  styleUrls: ['./prescription.component.scss']
})
export class PrescriptionComponent implements OnInit {
  parenteralPrescription: any[] = [
    {name:'Fluids (ml/kg)', value:0, composition: new FormControl()}, 
    {name:'Power kcal/kg/die', value:0, composition: new FormControl()}, 
    {name:'Protein g/kg/die', value:0, composition: new FormControl()}, 
    {name:'Carbs g/kg/die', value:0, composition: new FormControl()}, 
    {name:'Lipids g/kg/die', value:0, composition: new FormControl()}, 
    {name:'NA mmol/kg/die', value:0, composition: new FormControl()}];
  dataParentalPrescription = new MatTableDataSource<Element>(this.parenteralPrescription);
  displayedColumns = ['name', "value", 'composition'];
  constructor() { }

  ngOnInit() {
  }

}
