import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Growths } from './../_interfaces/growths.interface';
import { Baby } from './../_interfaces/baby.interface';

@Injectable({
  providedIn: 'root'
})
export class NutrizioneService {

  constructor(private http: HttpClient) { }

  baby(){
   /*let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'json' })
    };
    let body = JSON.stringify({'id':baby});*/
    return this.http.get('http://api.nutrizione.jef.it/service/babies')
  }

  mother(){
    return this.http.get('http://api.nutrizione.jef.it/service/mother-anagraphic')
  }
  
  growths(baby, type){
   /*let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'json' })
    };
    let body = JSON.stringify({'id':baby});*/
    return this.http.get('http://api.nutrizione.jef.it/service/growths/'+baby+'?method='+type)
    //return this.http.post('http://api.nutrizione.jef.it/service/growths/baby?method=get', body, httpOptions)
  }

  nutritionInstruments(){
    return this.http.get('http://api.nutrizione.jef.it/service/nutritionalinstruments')
  }

  alimenti(){
    return this.http.get('http://api.nutrizione.jef.it/service/defelencoalim')
  }

  latti(){
    return this.http.get('http://api.nutrizione.jef.it/service/defelencolatti')
  }

  deftestaprog(){
    return this.http.get('http://api.nutrizione.jef.it/service/deftestaprog')
  }

  lattiobsv(){
    return this.http.get('http://api.nutrizione.jef.it/service/defelencolatti')
  }

  alimentiobsv(){
    return this.http.get('http://api.nutrizione.jef.it/service/defelencoalim')
  }

  defprogrammi(){
    return this.http.get('http://api.nutrizione.jef.it/service/defprogrammi')
  }

  defalimenti(){
    return this.http.get('http://api.nutrizione.jef.it/service/defalimenti')
  }
  
}
