import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { InternalizationService } from './../_services/internalization.service';

@Component({
  selector: 'sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss']
})

export class SidebarNavComponent {
  selected:string 
  langs:any
  selectedValue: string;
  isHandset: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.Handset);
  
  constructor(private breakpointObserver: BreakpointObserver, private translate:InternalizationService) {
    this.langs = translate.translate.store.langs
    this.selectedValue = translate.translate.store.currentLang
  }

  changeLanguage(event){
    this.translate.translate.use(event.value);
  }

}

