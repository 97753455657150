import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-recommended',
  templateUrl: './recommended.component.html',
  styleUrls: ['./recommended.component.scss']
})
export class RecommendedComponent implements OnInit {
  parenteralRecommended: any[] = [{name:'Fluids (ml/kg)', value:0}, {name:'Power kcal/kg/die', value:0}, {name:'Protein g/kg/die', value:0}, {name:'Carbs g/kg/die', value:0}, {name:'Lipids g/kg/die', value:0}, {name:'NA mmol/kg/die', value:0}];
  dataParentalRecommended = new MatTableDataSource<Element>(this.parenteralRecommended);
  displayedColumns = ['name', "value"];
  
  constructor() { }

  ngOnInit() {
  }

}
