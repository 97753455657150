
import {from as observableFrom,  Observable, ConnectableObservable, Observer, Subscription, fromEvent } from 'rxjs';
import { Component, OnChanges, Output, SimpleChanges, OnInit, Input, EventEmitter, ViewChild, Renderer2} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'

import "rxjs/add/operator/filter";
import { Baby } from './../../_interfaces/baby.interface';
import {FormControl, FormGroup, NgForm, Validators, FormBuilder} from '@angular/forms';
import { Deftestaprog } from './../../_interfaces/deftestaprog.interface';
import { Defprogrammi } from './../../_interfaces/defprogrammi.interface';
import { Nutritionalinstruments } from './../../_interfaces/nutritionalinstruments.interface';
import { InternalizationService } from './../../_services/internalization.service';
import { NutrizioneService } from './../../_services/nutrizione.service';
import { EnteralSharedService } from './../../_services/enteralShared.service';
import { Alimenti } from './../../_interfaces/alimenti.interface';
import { Latti } from './../../_interfaces/latti.interface';
import { MyErrorStateMatcher } from './../../_models/nutrizione/validator';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Enteral } from './../../_models/nutrizione/enteral';
import * as moment from 'moment';
import { catchError, map, tap,startWith, switchMap, debounceTime, distinctUntilChanged, takeWhile, first, filter } from 'rxjs/operators';
import { DialogComponent } from '../enteral/dialog/dialog.component';
import {MatTableDataSource} from '@angular/material';
import { Element } from './../../_interfaces/element.interface';
import { Element2 } from './../../_interfaces/element2.interface';
import {SelectionModel} from '@angular/cdk/collections';
import { Prescription, LatteModel, AlimentiModel } from './../../_models/nutrizione/prescription';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-nutrizione-head',
  templateUrl: './nutrizione-head.component.html',
  styleUrls: ['./nutrizione-head.component.scss']
})

export class NutrizioneHeadComponent implements OnInit {
  
  @Input('babydata') 
  baby:any = [];
  alimenti: Alimenti[] = [];
  milks: Latti[] = [];
  enteral = new Enteral();
  isLoading = false;
  volumeschema:any
  nmeals:any
  fluids = 0
  nutritionalInstruments:any
  vollatteprogramma:any
  selectedDilution='100'
  selectedMaterno=1
  latte: string;
  arrayalimenti:any[];
  schema:any[];
  selectedSchema:any;
  @ViewChild('fluidsChildren', { static: false }) 
  private fluidsChildren: any;
  ELEMENT_DATA: Element2[] = [
    { empty:'', position: 1, supplement: '', vit: "", meals: "", volume:"", dilution:"", mealvolume:"" }
  ];
  ELEMENT_DATA2: Element[] = [
    { position: 0, supplement: '', vit: "", meals: "", volume:"", dilution:"", mealvolume:"" }
  ];
  displayedColumns = ['select', "position", "supplement", "vit","meals","volume","dilution","mealvolume"];
  displayedColumns2 = ["empty","position", "supplement", "vit","meals","volume","dilution","mealvolume"];
  dataSource = new MatTableDataSource<Element>();
  dataSource2 = new MatTableDataSource<Element>(this.ELEMENT_DATA2);
  selection = new SelectionModel<Element>(true, []);
  selectSupplement = false
  myControl = new FormControl();
  myControlalimenti = new FormControl();
  prescription = new Prescription();
  
  


  
  matcher = new MyErrorStateMatcher();
  myformGroup:FormGroup;
  @Output() refreshData=new EventEmitter();
  isVisible: boolean = false;
 constructor(private sharedService:EnteralSharedService,public dialog: MatDialog, private http: HttpClient, private translate:InternalizationService, private nutrizioneService:NutrizioneService, private renderer:Renderer2, fb: FormBuilder) {
    

    this.myformGroup = fb.group({
      volumeschema: ["", Validators.required],
      drugs: ["", Validators.required],
      fluids:  new FormControl({value: 0, disabled: true}, Validators.required),
      modality: ["", Validators.required]
    })

    

     
  
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      height: '200px',
      width: '300px',
      data:{'baby':this.baby.baby.id, 'weight':null, 'length':this.baby.growthsLast.length, 'tibia':this.baby.growthsLast.tibia,'cranic':this.baby.growthsLast.cranic}
    });

    dialogRef.afterClosed().subscribe(result => {
     if(result  == 'refresh'){
        this.refreshData.emit();
     }
    });
  }

 
 
  ngOnChanges(changes: SimpleChanges) {
    if (changes['baby'] && this.baby) {
      //Schema
      console.log('change'+this.baby)
      this.schemaService()
      //Nutritional Instruments
      this.nutritionalInstrumentsService(this.translate.translate.store.currentLang)
    }

  }

  

  nutritionalInstrumentsService(lang){
    this.isLoading = true
    this.nutrizioneService.nutritionInstruments()
    .subscribe( (instruments:Nutritionalinstruments[]) => {
      this.nutritionalInstruments = instruments
      this.isLoading = false
    })
  }

  
  schemaService(){
    this.isLoading = true
    const obsvPromiseDeftestaprog = observableFrom(this.nutrizioneService.deftestaprog())
    obsvPromiseDeftestaprog.pipe(
      map((deftestaprog:any) => {
        let peso = this.baby.growthsLast.weight
        deftestaprog = deftestaprog.filter(deftestaprog => deftestaprog.minimo <= peso && deftestaprog.massimo >= peso)
        return deftestaprog
      }))
      .subscribe( 
        (deftestaprog:Deftestaprog[]) => {
          this.schema = deftestaprog
        },
        error => {
          console.log(error)
        }
      )  
  }

  schemaScelto(event, schema){
    if(event.isUserInput){
      console.log(schema)
      this.selectedSchema = schema.id
      this.programmiService(schema)
      
    }
  }

  programmiService(schema){
    let idprogramma = schema.idprescrizione
    //Defprogrammi
    const obsvPromiseDefprogrammi = observableFrom(this.nutrizioneService.defprogrammi())
    obsvPromiseDefprogrammi.pipe(
      map((defprogrammi:any) => {
        let nascita = moment(this.baby.baby.birthday)
        let today = moment()
        let days = moment(today).diff(nascita, 'days')
        console.log(days)
        defprogrammi = defprogrammi.filter(defprogrammi => defprogrammi.idprescrizione == idprogramma)
        console.log(defprogrammi)
        let checkexistday = false
        for(let row of defprogrammi){
          if(row.descrizione == days){
            checkexistday = true
          }
        }
        if(checkexistday){
          defprogrammi = defprogrammi.filter(defprogrammi => defprogrammi.descrizione == days)
        }else{
          defprogrammi = defprogrammi[defprogrammi.length-1]
        }
        return defprogrammi
      }))
      .subscribe( 
        (defprogrammi:any) => {
          console.log('defprogrammi',defprogrammi);
         let shared = {}
         shared['idprogramma'] = idprogramma
         shared['volumeschema'] = defprogrammi.volume
         shared['vollatte'] = defprogrammi.vollatte
         shared['numpasti'] = schema.numpasti  
         this.sharedService.schemaSource.next(shared);
          //this.myformGroup.controls['volumeschema'].setValue(defprogrammi.volume)
          this.enteral.volumeschema = defprogrammi.volume
          this.myformGroup.patchValue({volumeschema: parseInt(defprogrammi.volume)})
          this.vollatteprogramma = defprogrammi.vollatte
          
          this.isLoading = false
        },
        error => {
          console.log(error)
        }
      )
  }

 



  ngOnInit() {
    //Buttons Table Dynamic
    const addel = document.getElementById('addRowButtom');
    const controladd = fromEvent(addel, 'click');
    controladd.subscribe(click => {
      if(this.dataSource.data.length > 1){
        this.selectSupplement = true
      }else{
        this.selectSupplement = false
      }
    })
    const removeel = document.getElementById('removeRowButtom');
    const controlremove = fromEvent(removeel, 'click');
    controlremove.subscribe(click => {
      if(this.dataSource.data.length > 1){
        this.selectSupplement = true
      }else{
        this.selectSupplement = false
      }
    })


    //Observer total fuilds input
    this.myformGroup.get('volumeschema').valueChanges.subscribe(value => { 
        this.differenceFuildsByfluids(value)
    })
    //Observer input drugs
    this.myformGroup.get('drugs').valueChanges.subscribe(value => { 
        this.differenceFuildsBydrugs(value)
    })

    this.prescription.position = 0;
//console.log('enteral baby')
//console.log(this.baby)
  }

  differenceFuildsBydrugs(input){
    //Observer totalfluids
    this.fluids = (this.myformGroup.get('volumeschema').value-input)
    this.enteral.fluids = this.fluids
    this.enteral.drugs = input
    this.myformGroup.patchValue({fluids: this.fluids})
    let fuildInput = this.fluidsChildren.nativeElement;
    if(this.fluids < 0){
      this.renderer.addClass(fuildInput, 'red')
    }else{
      this.renderer.removeClass(fuildInput, 'red')
    }
  }

  differenceFuildsByfluids(input){
    //Observer drugs
    this.fluids = (input-this.myformGroup.get('drugs').value)
    this.myformGroup.patchValue({fluids: this.fluids})
    this.enteral.fluids = this.fluids
    this.enteral.volumeschema = input
    let fuildInput = this.fluidsChildren.nativeElement;
    if(this.fluids < 0){
      this.renderer.addClass(fuildInput, 'red')
    }else{
      this.renderer.removeClass(fuildInput, 'red')
    }
  }

}
