import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatTableDataSource} from '@angular/material';
import { DataBaby } from './../_interfaces/datababy.interface';
import { Growths } from './../_interfaces/growths.interface';
import { Baby } from './../_interfaces/baby.interface';
import { InternalizationService } from './../_services/internalization.service';
import { Observable, ConnectableObservable, Observer, Subscription, fromEvent } from 'rxjs';

import "rxjs/add/operator/map";
import "rxjs/add/operator/last";
import "rxjs/add/operator/filter";
import * as moment from 'moment';

@Component({
  selector: 'app-baby',
  templateUrl: './baby.component.html',
  styleUrls: ['./baby.component.scss']
})

export class BabyComponent implements OnInit {
  @Input('babydata') 
  baby:any = [];

  ELEMENT_DATA: any[] = []
  ELEMENT_DATA2: any[] = []
  displayedColumns = ['val1', "val2", "val3", "val4","val5","val6","val7","val8","val9","val10","val11","val12"];
  displayedColumns2 = ['val1', "val2", "val3", "val4","val5","val6","val7","val8","val9","val10","val11","val12"];
  dataSource:any
  dataSource2:any;
  //baby: any;
  growths:any;
  baby_id:any;
  isLoading = false;
  isDevelop = false;
  mother:any

  constructor(private http: HttpClient,  
    private translate:InternalizationService) {}

  ngOnChanges(changes: SimpleChanges) {
      
        if (changes['baby'] && this.baby) {
         
            let obj = {}
            obj['val1'] = 'BABYDATA.VOL1-1'
            obj['val2'] = this.baby.baby.name
            obj['val3'] = 'BABYDATA.VOL3-1'
            obj['val4'] = this.baby.baby.surname
            obj['val5'] = 'BABYDATA.VOL5-1'
            obj['val6'] = this.baby.baby.gender
            obj['val7'] = 'BABYDATA.VOL7-1'
            obj['val8'] = this.baby.baby.birthday + ' ' +this.baby.baby.birthtime
            obj['val9'] = ""
            obj['val10'] = ""
            obj['val11'] = ""
            obj['val12'] = ""
            this.ELEMENT_DATA.push(obj)

            //RIGA 2
            let gweek_age = Math.round((parseInt(this.baby.baby.gestionalAge)/7))
            let gdays_age = Math.round((parseInt(this.baby.baby.gestionalAge)%7))
            
            let obj2 = {}
            obj2['val1'] = 'BABYDATA.VOL1-2'
            obj2['val2'] = this.baby.baby.gestionalAge
            obj2['val3'] = 'BABYDATA.VOL3-2'
            obj2['val4'] = '( ' + gweek_age + ' + ' + gdays_age + ' )';
            obj2['val5'] = 'BABYDATA.VOL5-2'
            obj2['val6'] = 'schema attesa'
            obj2['val7'] = 'BABYDATA.VOL7-2'
            obj2['val8'] = 'start enteral'//baby[0].birthday
            obj2['val9'] = ""
            obj2['val10'] = ""
            obj2['val11'] = ""
            obj2['val12'] = ""
            this.ELEMENT_DATA.push(obj2)



            let obj3 = {}
            obj3['val1'] = 'BABYDATA.VOL1-3'
            obj3['val2'] = this.baby.growthsFirst.weight
            obj3['val3'] = 'BABYDATA.VOL3-3'
            obj3['val4'] = 'attesa'
            obj3['val5'] = 'BABYDATA.VOL5-3'
            obj3['val6'] = this.baby.growthsFirst.length
            obj3['val7'] = 'BABYDATA.VOL3-3'
            obj3['val8'] = 'attesa'
            obj3['val9'] = "BABYDATA.VOL9-3"
            obj3['val10'] = this.baby.growthsFirst.cranic
            obj3['val11'] = "BABYDATA.VOL3-3"
            obj3['val12'] = "attesa"
            this.ELEMENT_DATA.push(obj3)

            this.dataSource = new MatTableDataSource<Element>(this.ELEMENT_DATA);

            //RIGA 4
            let today = moment().format('YYYY-MM-DD')
            let dayslife = moment().diff(this.baby.baby.birthday, 'days');
            let dayslifeComplete = moment().diff(this.baby.baby.birthday+' '+this.baby.baby.birthtime, 'days');
            this.ELEMENT_DATA2 = [
            { 
              val1: 'BABYDATA.VOL1-4', 
              val2: today, 
              val3: "", 
              val4: "", 
              val5: 'BABYDATA.VOL5-4', 
              val6: dayslife, 
              val7: "", 
              val8: "",
              val9: "BABYDATA.VOL6-4", 
              val10: dayslifeComplete, 
              val11: "", 
              val12: "" 
            },
            { 
              val1: 'BABYDATA.VOL1-2', 
              val2: "attesa", 
              val3: 'BABYDATA.VOL3-2', 
              val4: "attesa+calcolo", 
              val5: 'BABYDATA.VOL5-5', 
              val6: "attesa data", 
              val7: "", 
              val8: "",
              val9: "", 
              val10: "", 
              val11: "", 
              val12: "" 
            },
            { 
              val1: 'BABYDATA.VOL1-6', 
              val2: this.baby.growthsLast.weight, 
              val3: 'BABYDATA.VOL3-3',
              val4: "attesa", 
              val5: 'BABYDATA.VOL5-6', 
              val6: this.baby.growthsLast.length, 
              val7: 'BABYDATA.VOL3-3', 
              val8: "attesa",
              val9: 'BABYDATA.VOL9-6', 
              val10: this.baby.growthsLast.cranic, 
              val11: 'BABYDATA.VOL3-3', 
              val12: "attesa" 
            }
          ];
          this.dataSource2 = new MatTableDataSource<Element>(this.ELEMENT_DATA2);

        }
    }

  ngOnInit() {}

}


