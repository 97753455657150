import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class BabyheadService {
  
  isLoading = false
  isDevelop = false

  baby_id:any
  mother:any
  babydata:any
  growths:any
  baby:any

  constructor(private http: HttpClient) { }

   babiHead(baby){
    return this.http.get('http://api.nutrizione.jef.it/service/babies/'+baby)
  }

}
