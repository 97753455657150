import { Component, OnInit } from '@angular/core';
import {from as observableFrom,  Observable, ConnectableObservable, Observer, Subscription, fromEvent } from 'rxjs';
@Component({
  selector: 'app-parenteral',
  templateUrl: './parenteral.component.html',
  styleUrls: ['./parenteral.component.scss']
})
export class ParenteralComponent implements OnInit {
  accessoOptions: any[] = [{name:'Accesso Venoso', value:0}];
  
  constructor() { }

  ngOnInit() {
  }

}
