import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class EnteralSharedService {
  schemaSource: BehaviorSubject<any> = new BehaviorSubject(false);
  constructor() { }

}
