import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-parental',
  templateUrl: './header-parental.component.html',
  styleUrls: ['./header-parental.component.scss']
})
export class HeaderParentalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
