import { Component, OnInit } from '@angular/core';
import {MatTableDataSource} from '@angular/material';

@Component({
  selector: 'app-intakes',
  templateUrl: './intakes.component.html',
  styleUrls: ['./intakes.component.scss']
})
export class IntakesComponent implements OnInit {
  parenteralIntakes: any[] = [{name:'Fluids (ml/kg)', value:0}, {name:'Power kcal/kg/die', value:0}, {name:'Protein g/kg/die', value:0}, {name:'Carbs g/kg/die', value:0}, {name:'Lipids g/kg/die', value:0}, {name:'NA mmol/kg/die', value:0}];
  dataParentalIntakes = new MatTableDataSource<Element>(this.parenteralIntakes);
  displayedColumns = ['name', "value"];
  
  constructor() { }

  ngOnInit() {
  }

}
