import { Component, Inject, EventEmitter } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {FormControl, FormGroup, NgForm, Validators, FormBuilder} from '@angular/forms';
import {BabyService} from './../../../_services/baby.service'
import {BabyheadService} from './../../../_services/babyhead.service'
import * as moment from 'moment';

export interface DialogData {
  baby: number;
  weight: number;
  tibia: number;
  length: number;
  cranic: number;
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})

export class DialogComponent {
  
  registerBabyWeight: FormGroup;
  submittedDialog = false;
  dialogBaby:any
  constructor(
    private head:BabyheadService,
    private service:BabyService,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private formBuilder: FormBuilder) {
      this.registerBabyWeight = this.formBuilder.group({
        baby: ['', Validators.required], 
        weight: ['', Validators.required],
        tibia: ['', Validators.required],
        length: ['', Validators.required],
        cranic: ['', Validators.required]
      }); 
      this.registerBabyWeight.setValue(this.data)
    }

  onNoClick(): void {
    this.dialogRef.close();
  }
  get s() { return this.registerBabyWeight.controls; }
  onSubmit() {
    this.submittedDialog = true;
    console.log(this.registerBabyWeight)
   // console.log(this.registerForm.controls['startDate'].valid)
    if (this.registerBabyWeight.invalid) {
        return;
    }
    let babyWeight = {}
    babyWeight['baby'] = this.registerBabyWeight.get('baby').value
    babyWeight['date'] = moment().format('YYYY-MM-DD HH:mm:ss')
    babyWeight['weight'] = this.registerBabyWeight.get('weight').value
    babyWeight['length'] = this.registerBabyWeight.get('length').value
    babyWeight['cranic'] = this.registerBabyWeight.get('cranic').value
    babyWeight['tibia'] = this.registerBabyWeight.get('tibia').value

    //Update
    this.service.newGrowths(babyWeight).subscribe(data =>{
      
      this.dialogRef.close('refresh');
      this.registerBabyWeight.reset();
    })
    
  }
}
