
import {from as observableFrom,  Observable, ConnectableObservable, Observer, Subscription, fromEvent } from 'rxjs';
import { Component, OnChanges, Output, SimpleChanges, OnInit, Input, EventEmitter, ViewChild, Renderer2} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'

import "rxjs/add/operator/filter";
import { Baby } from './../../_interfaces/baby.interface';
//import {NgForm, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {FormControl, FormGroup, NgForm, Validators, FormBuilder} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatTableDataSource} from '@angular/material';
import { Element } from './../../_interfaces/element.interface';
import { Element2 } from './../../_interfaces/element2.interface';
import { Deftestaprog } from './../../_interfaces/deftestaprog.interface';
import { Defprogrammi } from './../../_interfaces/defprogrammi.interface';
import { Nutritionalinstruments } from './../../_interfaces/nutritionalinstruments.interface';
import {SelectionModel} from '@angular/cdk/collections';
import { InternalizationService } from './../../_services/internalization.service';
import { NutrizioneService } from './../../_services/nutrizione.service';
import { Alimenti } from './../../_interfaces/alimenti.interface';
import { Latti } from './../../_interfaces/latti.interface';
import { Enteral } from './../../_models/nutrizione/enteral';
import { Prescription, LatteModel, AlimentiModel } from './../../_models/nutrizione/prescription';
import { MyErrorStateMatcher } from './../../_models/nutrizione/validator';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EnteralSharedService } from './../../_services/enteralShared.service';

import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import * as moment from 'moment';
import { catchError, map, tap,startWith, switchMap, debounceTime, distinctUntilChanged, takeWhile, first, filter } from 'rxjs/operators';
import { DialogComponent } from './dialog/dialog.component';

//import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-enteral',
  templateUrl: './enteral.component.html',
  styleUrls: ['./enteral.component.scss']
})

export class EnteralComponent implements OnInit{
  @Input('babydata') 
  baby:any = [];
  alimenti: Alimenti[] = [];
  milks: Latti[] = [];
  ELEMENT_DATA: Element2[] = [
    { empty:'', position: 1, supplement: '', vit: "", meals: "", volume:"", dilution:"", mealvolume:"" }
  ];
  ELEMENT_DATA2: Element[] = [
    { position: 0, supplement: '', vit: "", meals: "", volume:"", dilution:"", mealvolume:"" }
  ];
  displayedColumns = ['select', "position", "supplement", "vit","meals","volume","dilution","mealvolume"];
  displayedColumns2 = ["empty","position", "supplement", "vit","meals","volume","dilution","mealvolume"];
  dataSource = new MatTableDataSource<Element>();
  dataSource2 = new MatTableDataSource<Element>(this.ELEMENT_DATA2);
  selection = new SelectionModel<Element>(true, []);
  selectSupplement = false
  isLoading = false;
  volumeschema:any
  nmeals:any
  fluids = 0
  nutritionalInstruments:any
  vollatteprogramma:any
  selectedDilution='100'
  selectedDilution2='100'
  selectedDilutionAlimenti='100'
  selectedMaterno=1
  filteredBrands: any[];
  latte: string;
  arrayalimenti:any[];
  defalimentiList:any

  @ViewChild('fluidsChildren', { static: false }) 
  private fluidsChildren: any;
  
  @Output() schemaEm=new EventEmitter();
  myControl = new FormControl();
  filteredOptions: Observable<any[]>;
  myControlalimenti = new FormControl();
  filteredOptionsAlimenti: Observable<any[]>;
  
  enteral = new Enteral();
  prescription = new Prescription();
  alimento = new AlimentiModel();
  matcher = new MyErrorStateMatcher();
  myformGroup:FormGroup;
  dataprescripton:any
  @Output() refreshData=new EventEmitter();
  prescriberEnteral: any[] = [{name:'Fluids (ml/kg)', value:0}, {name:'Power kcal/kg/die', value:0}, {name:'Protein g/kg/die', value:0}, {name:'Carbs g/kg/die', value:0}, {name:'Lipids g/kg/die', value:0}, {name:'NA mmol/kg/die', value:0}];
  constructor(private sharedService:EnteralSharedService,public dialog: MatDialog, private http: HttpClient, private translate:InternalizationService, private nutrizioneService:NutrizioneService, private renderer:Renderer2, fb: FormBuilder) {
    

    this.myformGroup = fb.group({
      volumeschema: ["", Validators.required],
      drugs: ["", Validators.required],
      fluids:  new FormControl({value: 0, disabled: true}, Validators.required),
      modality: ["", Validators.required]
    })

    

    this.filteredOptions = this.myControl.valueChanges
        .pipe(
          startWith(null),
          debounceTime(200),
          distinctUntilChanged(),
          switchMap(val => {
            return this.filter(val || '')
          })       
        );
    this.filteredOptionsAlimenti = this.myControlalimenti.valueChanges
        .pipe(
          startWith(null),
          debounceTime(200),
          distinctUntilChanged(),
          switchMap(val => {
            return this.filteralimenti(val || '')
          })       
        );    
  
  }

 

  filter(val: string): Observable<any[]> {
    const obsvPromiseLatte = observableFrom(this.nutrizioneService.defalimenti())
    return obsvPromiseLatte
    .pipe(
      map((response:any) => response.filter(option => { 
        return option.descrizione.toLowerCase().indexOf(val.toLowerCase()) === 0
      }))
    )
  }

  filteralimenti(val: string): Observable<any[]> {
    const obsvPromiseAlimenti = observableFrom(this.nutrizioneService.defalimenti())
    return obsvPromiseAlimenti
    .pipe(
      map((response:any) => response.filter(option => { 
        return option.descrizione.toLowerCase().indexOf(val.toLowerCase()) === 0
      }))
    )
  }
 
  ngOnChanges(changes: SimpleChanges) {
    if (changes['baby'] && this.baby) {
      //Schema
      //console.log('change'+this.baby)
      //Nutritional Instruments
      this.nutritionalInstrumentsService(this.translate.translate.store.currentLang)
    }

  }

  

  nutritionalInstrumentsService(lang){
    this.isLoading = true
    this.nutrizioneService.nutritionInstruments()
    .subscribe( (instruments:Nutritionalinstruments[]) => {
      this.nutritionalInstruments = instruments
      this.isLoading = false
    })
  }

  

  latteChange(event, latte, position){
    if(event.isUserInput){
      console.log(latte)
        let milkIntPres =  (<any>document.getElementById('milkIntPres'))
        milkIntPres.value = 0
        let milkMealPres =  (<any>document.getElementById('milkMealPres'))
        milkMealPres.value = this.nmeals
        let milkVolPres =  (<any>document.getElementById('milkVolPres'))
        milkVolPres.value = 0
        let milkDilPres =  (<any>document.getElementById('milkDilPres'))
      
        milkDilPres.value = '100'
        let milkVolTotPres =  (<any>document.getElementById('milkVolTotPres'))
        milkVolTotPres.value = this.calcVolume(this.vollatteprogramma)
        //model
        this.prescription.position = 0;
        let latteModel = new LatteModel()
        latteModel.supplement = parseInt(latte.id)
        latteModel.int = parseFloat(milkIntPres.value) //?obs
        latteModel.nmeals = parseInt(this.nmeals) //?obs
        latteModel.volumekg = parseFloat(milkVolPres.value) //?obs
        latteModel.dilution = parseInt(milkDilPres.value) //?obs
        latteModel.volumeml = parseFloat(milkVolTotPres.value) //?obs
        this.prescription.latte = latteModel;
        //console.log(this.prescription)
        this.dataprescripton.next(this.prescription)
    }
  }

  alimentiChange(event, alimenti, position){
    if(event.isUserInput){
      let alimentiIntPres =  (<any>document.getElementById(position+'-alimentiIntPres'))
      alimentiIntPres.value = 0
      let alimentiMealPres =  (<any>document.getElementById(position+'-alimentiMealPres'))
      alimentiMealPres.value = this.nmeals
      let alimentiVolPres =  (<any>document.getElementById(position+'-alimentiVolPres'))
      alimentiVolPres.value = 0
      let alimentiDilPres =  (<any>document.getElementById(position+'-alimentiDilPres'))
      let alimentiVolTotPres =  (<any>document.getElementById(position+'-alimentiVolTotPres'))
      alimentiVolTotPres.value = this.calcVolume(this.vollatteprogramma)
      
     
      //model
      let searchAlimento = this.prescription.alimenti.filter(x => x.position === position);
      
      /*this.prescription.position = position;*/
      searchAlimento[0].supplement = parseInt(alimenti.id)
      searchAlimento[0].int = parseFloat(alimentiIntPres.value) //?obs
      searchAlimento[0].nmeals = parseInt(this.nmeals) //?obs
      searchAlimento[0].volumekg = parseFloat(alimentiVolPres.value) //?obs
      searchAlimento[0].volumeml = parseFloat(alimentiVolTotPres.value) //?obs
      
      this.dataprescripton.next(this.prescription)
    }
  }

  alimentiChangeReverse(event, position){
      let searchAlimento = this.prescription.alimenti.filter(x => x.position === position);
      let alimentiVolPres =  (<any>document.getElementById(position+'-alimentiVolPres'))
      alimentiVolPres.value = this.calcVolumeReverse(event)
      searchAlimento[0].volumekg = parseFloat(alimentiVolPres.value)
      searchAlimento[0].volumeml = event
      this.dataprescripton.next(this.prescription)
    
  }

  intVitChange(int, position){
    
    let searchAlimento = this.prescription.alimenti.filter(x => x.position === position);
    searchAlimento[0].int = parseFloat(int)
    this.dataprescripton.next(this.prescription)
  }
  
  mealChange(meals, position){
    let searchAlimento = this.prescription.alimenti.filter(x => x.position === position);
    searchAlimento[0].nmeals = parseInt(meals)
    this.dataprescripton.next(this.prescription)
  }

  mealAlimentiChange(volume, position){
    let alimentiDilPres =  (<any>document.getElementById(position+'-alimentiDilPres'))
    alimentiDilPres.value = this.calcDilution(volume)
    let alimentiVolTotPres =  (<any>document.getElementById(position+'-alimentiVolTotPres'))
    alimentiVolTotPres.value = this.calcVolume(volume)
    let searchAlimento = this.prescription.alimenti.filter(x => x.position === position);
    searchAlimento[0].volumekg = parseFloat(volume)
    searchAlimento[0].volumeml = parseFloat(alimentiVolTotPres.value)
    this.dataprescripton.next(this.prescription)
  }

  dilutionChange(dil, position){
    if(dil.isUserInput){
      let searchAlimento = this.prescription.alimenti.filter(x => x.position === position)
      searchAlimento[0].dilution = parseInt(dil.source.value)
      this.dataprescripton.next(this.prescription)
    }
  }

  /*MILK*/

  mealMilkChange(volume){
    let milkDilPres =  (<any>document.getElementById('milkDilPres'))
    milkDilPres.value = volume
    this.prescription.latte.volumekg = parseFloat(volume)
    
    let milkVolTotPres =  (<any>document.getElementById('milkVolTotPres'))
    milkVolTotPres.value = this.calcVolume(volume)
    this.prescription.latte.volumeml = parseFloat(milkVolTotPres.value)
    this.dataprescripton.next(this.prescription)
  }

  mealMilkChangeReverse(volume){
    let milkVolTotPres =  (<any>document.getElementById('milkVolTotPres'))
    milkVolTotPres.value = volume
    this.prescription.latte.volumeml = parseFloat(volume)
    //this.calcVolume(volume)
    
    let milkVolPres =  (<any>document.getElementById('milkVolPres'))
    milkVolPres.value = this.calcVolume(volume)
    this.prescription.latte.volumekg = parseFloat(milkVolPres.value)
    this.dataprescripton.next(this.prescription)
  }

  milkIntVitChange(int){
    this.prescription.latte.int = parseFloat(int)
    this.dataprescripton.next(this.prescription)
  }
  
  milkMealChange(meals){
    this.prescription.latte.nmeals = parseInt(meals)
    this.dataprescripton.next(this.prescription)
  }
 
  milkDilutionChange(dil){
    if(dil.isUserInput){
      if(this.prescription.latte){
      this.prescription.latte.dilution = parseInt(dil.source.value)
      this.dataprescripton.next(this.prescription)
      }
    }
  }
  

  calcDilution(volume){
    return ((100*75)/volume).toFixed(2)
  }

  calcDilutionReverse(volume){
    return ((100*75)/volume).toFixed(2)
  }

  calcVolume(volume){
    return ((volume*<any>this.baby.growthsLast.weight)/1000).toFixed(2)
  }

  calcVolumeReverse(volume){
    return ((volume/<any>this.baby.growthsLast.weight)*1000).toFixed(2)
  }

   masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  addRow() {
    
    let newid 
    let nuevo = this.dataSource.data.length
    if(nuevo == 0){
      newid = 1
    }else{
      let orden = this.dataSource.data
      orden.sort(function (a, b) {
        return a.position - b.position;
      });
      let last = orden[orden.length - 1].position
      newid = last+1
    }

    let newAlimento = new AlimentiModel()
    newAlimento.position = newid
    this.prescription.alimenti.push(newAlimento)
    this.dataSource.data.push({
      position: newid,
      supplement: null,
      vit: null,
      meals: null,
      volume: null,
      dilution: 100,
      mealvolume: null
    });
    this.dataSource.filter = ""
    console.log(this.prescription)
    this.dataprescripton.next(this.prescription)
  }

  removeRow() {
    for(let rowselected of this.selection.selected){
      for(let i =0; i < this.dataSource.data.length; i++){
        if(rowselected.position == this.dataSource.data[i].position){
          this.dataSource.data.splice(i, 1)
          this.prescription.alimenti.splice(i, 1);
        }
      }  
    }
    this.selection.clear()
    this.dataSource.filter = "";
    
    this.dataprescripton.next(this.prescription)
  }
  
 

  modality(event, instrument){
    if(event.isUserInput){
      this.enteral.modality = instrument.id
    }
  }


  
  
  

 onSubmit() {
   
    //console.log("Recomended: " + JSON.stringify(this.enteral));
    //console.log("Prescription: " + JSON.stringify(this.prescription));
    //console.log("Thanks for submitting! Data: " + JSON.stringify(this.recomended));
    //console.log(this.nutrizioneFormControl)
  }

  //CALCOLI DX
  calcFluidsDx(volumeKgDx){
    return ((volumeKgDx/<any>this.baby.growthsLast.weight)*this.nmeals).toFixed(2)
  }

  calcPowerDx(poweDx){
    return ((poweDx/<any>this.baby.growthsLast.weight)*this.nmeals).toFixed(2)
  }




  ngOnInit() {
    this.nutrizioneService.defalimenti()
      .subscribe( (defalimenti:any) => {
        this.defalimentiList = defalimenti
        console.log( defalimenti)
      })

    this.dataprescripton = new BehaviorSubject(this.prescription);
    this.dataprescripton.subscribe(data => {
      console.log(this.prescription)
      let volumeKgDx = 0
      let poweDx = 0
      let proteinDx = 0
      let carbsDx = 0
      let lipidsDx = 0
      let naDx = 0
      if (typeof this.prescription.latte !== 'undefined'){
        if (typeof this.prescription.latte.volumekg !== 'undefined'){
          //console.log(this.prescription.latte)
          volumeKgDx += this.prescription.latte.volumekg
        }
        if (typeof this.prescription.latte.supplement !== 'undefined'){
          let searchLatte = this.defalimentiList.filter(x => x.id === this.prescription.latte.supplement);
          poweDx += searchLatte[0].energia
          proteinDx += searchLatte[0].proteine
          
          carbsDx += searchLatte[0].glucidi
          lipidsDx += searchLatte[0].lipidi
          naDx += searchLatte[0].sodio
        }
      }
      if(typeof this.prescription.alimenti !== 'undefined'){
        for(let row of this.prescription.alimenti){
          if (typeof row.volumekg !== 'undefined'){
            volumeKgDx += row.volumekg
          }
          if (typeof row.supplement !== 'undefined'){
            let searchAlim = this.defalimentiList.filter(x => x.id === row.supplement);
            poweDx += searchAlim[0].energia
            proteinDx += searchAlim[0].proteine

            carbsDx += searchAlim[0].glucidi
            lipidsDx += searchAlim[0].lipidi
            naDx += searchAlim[0].sodio
          }
        }
      }
      console.log(proteinDx)
      
      //prescriberEnteral: any[] = [{name:'Fluids (ml/kg)', value:0}, {name:'Power kcal/kg/die', value:0}, {name:'Protein g/kg/die', value:0}, {name:'Carbs g/kg/die', value:0}, {name:'Lipids g/kg/die', value:0}, {name:'NA mmol/kg/die', value:0}];
      
      //FLUIDS  
      if(volumeKgDx !== 0){
        let calcfluids = this.calcFluidsDx(volumeKgDx)
        this.prescriberEnteral[0].value = calcfluids
      }
      //POWER
      if(poweDx !== 0){
        let calcpower = this.calcPowerDx(poweDx)
        this.prescriberEnteral[1].value = calcpower
      }
      //PROTEIN
      if(proteinDx !== 0){
        let calcprotein = this.calcPowerDx(proteinDx)
        this.prescriberEnteral[2].value = calcprotein
      }
      //CARBS
      if(carbsDx !== 0){
        let calccarbsDx = this.calcPowerDx(carbsDx)
        this.prescriberEnteral[3].value = calccarbsDx
      }
      //LIPIDS
      if(lipidsDx !== 0){
        let calcclipidsDx = this.calcPowerDx(lipidsDx)
        this.prescriberEnteral[4].value = calcclipidsDx
      }
      //NA
      if(naDx !== 0){
        let calcnaDx = this.calcPowerDx(naDx)
        this.prescriberEnteral[5].value = calcnaDx
      }
    });

    

    //Buttons Table Dynamic
    const addel = document.getElementById('addRowButtom');
    const controladd = fromEvent(addel, 'click');
    controladd.subscribe(click => {
      if(this.dataSource.data.length > 1){
        this.selectSupplement = true
      }else{
        this.selectSupplement = false
      }
    })
    const removeel = document.getElementById('removeRowButtom');
    const controlremove = fromEvent(removeel, 'click');
    controlremove.subscribe(click => {
      if(this.dataSource.data.length > 1){
        this.selectSupplement = true
      }else{
        this.selectSupplement = false
      }
    })
    this.sharedService.schemaSource.subscribe((schema: any) => {
      this.nmeals = schema.numpasti
      this.vollatteprogramma = schema.vollatte
    })
  }

  

}
